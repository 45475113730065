<template>
  <div class="search-ident-evt">
<!--    <div class="h-183px w-full relative mb-3">-->
<!--      <UploadSearch v-model:value="formState.image"></UploadSearch>-->
<!--    </div>-->

    <span class="text-sm text-ems-gray500 mb-1">{{
      t('identification-event.keyword')
    }}</span>
    <a-input
      type="text"
      v-model:value="formState.name"
      :placeholder="t('identification-event.keyword_placeholder')"
      class="ant-input-sm !mb-4 h-[38px]"
      :maxlength="255"
      :show-count="true"
      :allow-clear="true"
    />

    <div class="grid grid-cols-2 gap-2">
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.group_camera')
        }}</span>
        <SelectMulti
          class="!mb-4"
          :placeholder="t('identification-event.group_camera')"
          :options="listGroupCamera"
          v-model:value="formState.groupCameraIds"
          :allow-clear="true"
          mode="multiple"
          :fieldNames="{ label: 'name', value: 'id' }"
          :max-tag-count="3"
          :max-tag-text-length="12"
          :filter-name="'name'"
          @change="onChangeCameraGroup"
        >
        </SelectMulti>
      </div>
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.camera')
        }}</span>
        <SelectMulti
          class="!mb-4"
          :placeholder="t('identification-event.camera')"
          :options="listCameraByGroup"
          mode="multiple"
          v-model:value="formState.cameraIds"
          :fieldNames="{ label: 'name', value: 'id' }"
          :allow-clear="true"
          :is-show-search="true"
          :max-tag-count="3"
          :max-tag-text-length="12"
          :filter-name="'name'"
        >
        </SelectMulti>
      </div>
    </div>
    <div>
      <span class="text-sm text-ems-gray500 mb-1">{{
        t('identification-event.search-by-time')
      }}</span>

      <cds-from-to-date
        v-model:dateFrom="formState.dateFrom"
        v-model:dateTo="formState.dateTo"
        :is-show-label="false"
        :allow-clear="true"
        :from-label="t('identification-event.from_date')"
        :to-label="t('identification-event.to_date')"
        class="from-to-date"
        :gutter-row="8"
        size="large"
        :show-time="{ format: 'HH:mm' }"
        :format="'DD/MM/YYYY HH:mm'"
      />
    </div>
    <div class="grid grid-cols-2 gap-2">
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.search-by-type-warning')
        }}</span>

        <cds-select
          class="!mb-4"
          :placeholder="
            t('identification-event.search-by-type-warning-placeholder')
          "
          :options="WARNING_CONFIG_LIST"
          v-model:value="formState.isWarningConfig"
          :allow-clear="true"
        >
        </cds-select>
      </div>
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.search-by-status')
        }}</span>
        <cds-select
          class="!mb-4"
          :placeholder="t('identification-event.search-by-status-placeholder')"
          :options="EVENT_STATUS_LIST"
          v-model:value="formState.status"
          :allow-clear="true"
        >
        </cds-select>
      </div>

      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.search-by-type')
        }}</span>
        <cds-select
          class="!mb-4"
          :placeholder="t('identification-event.search-by-type-placeholder')"
          :options="OBJECT_TYPE_TEXT_VALUE"
          v-model:value="formState.type"
          :allow-clear="true"
          @change="onChangeObjectType"
        >
        </cds-select>
      </div>
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.search-by-group')
        }}</span>
        <SelectMulti
          class="!mb-4"
          :placeholder="t('identification-event.search-by-group-placeholder')"
          :options="listGroupByUser"
          v-model:value="formState.groupId"
          :allow-clear="true"
          mode="multiple"
          :fieldNames="{ label: 'name', value: 'id' }"
          :max-tag-count="3"
          :max-tag-text-length="12"
          :filter-name="'name'"
        >
        </SelectMulti>
      </div>
    </div>
    <div
      class="grid grid-cols-2 gap-2"
      v-if="formState.type === OBJECT_TYPE_TEXT_KEY.HUMAN"
    >
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.sex')
        }}</span>
        <cds-select
          class="!mb-4"
          :placeholder="t('identification-event.search-sex')"
          :options="SEX_VALUE"
          v-model:value="formState.gender"
          :allow-clear="true"
        >
        </cds-select>
      </div>
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.age')
        }}</span>
        <cds-select
          class="!mb-4"
          :placeholder="t('identification-event.search-age')"
          :options="listAge"
          v-model:value="formState.age"
          :allow-clear="true"
          label-prop="name"
          value-prop="value"
        >
        </cds-select>
      </div>

      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.race')
        }}</span>
        <cds-select
          class="!mb-4"
          :placeholder="t('identification-event.search-race')"
          :options="listRace"
          v-model:value="formState.race"
          :allow-clear="true"
          label-prop="name"
          value-prop="value"
        >
        </cds-select>
      </div>
    </div>

    <div
      class="grid grid-cols-2 gap-2"
      v-if="formState.type === OBJECT_TYPE_TEXT_KEY.VEHICLE"
    >
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.brand')
        }}</span>
        <cds-select
          class="!mb-4"
          :placeholder="t('identification-event.search-brand')"
          :options="listBrand"
          v-model:value="formState.vehicleBrand"
          :allow-clear="true"
          label-prop="name"
          value-prop="name"
        >
        </cds-select>
      </div>
      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.classify')
        }}</span>
        <cds-select
          class="!mb-4"
          :placeholder="t('identification-event.search-classify')"
          :options="listVehicleType"
          v-model:value="formState.vehicleName"
          :allow-clear="true"
          label-prop="name"
          value-prop="value"
        >
        </cds-select>
      </div>

      <div>
        <span class="text-sm text-ems-gray500 mb-1">{{
          t('identification-event.color')
        }}</span>
        <cds-select
          class="!mb-4"
          :placeholder="t('identification-event.search-color')"
          :options="listColor"
          v-model:value="formState.vehicleColor"
          :allow-clear="true"
          label-prop="name"
          value-prop="value"
        >
        </cds-select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SearchObject',
};
</script>
<script setup>
import { i18n } from '@/main';
import { defineEmits, reactive, computed, onMounted } from 'vue';
import UploadSearch from '@/components/upload-search/index.vue';
import SelectMulti from '@/components/select-multi/index.vue';
import {
  OBJECT_TYPE_TEXT_KEY,
  OBJECT_TYPE_TEXT_VALUE,
  SEX_VALUE,
  WARNING_CONFIG_LIST,
} from '@/util/common-constant';
import { useStore } from 'vuex';
import { EVENT_STATUS_LIST } from '../../../../util/common-constant';

const { t } = i18n.global;
const { state, dispatch } = useStore();
const listGroupByUser = computed(() => state.ioGroup.listGroupByUser);
const listGroupCamera = computed(() => state.tracingObject.listGroupCamera);
const listCameraByGroup = computed(() => state.tracingObject.listCameraByGroup);
const listAge = computed(() => state.listSelect.age);
const listRace = computed(() => state.listSelect['race']);
const listVehicleType = computed(() => state.listSelect.vehicleType);
const listBrand = computed(() => state.listSelect.brand);
const listColor = computed(() => state.listSelect.color);

const props = defineProps({
  formState: {
    default: {},
  },
});
const formState = reactive(props.formState);
const emits = defineEmits(['onSearchAdvanced', 'resetCameraId']);
const onChangeCameraGroup = () => {
  dispatch(
    'tracingObject/getListCameraByGroups',
    props.formState.groupCameraIds
  );
  emits('resetCameraId');
};
onMounted(() => {
  dispatch('ioGroup/getAllGroupByUser');
});

const onChangeObjectType = () => {
  formState.age = null;
  formState.gender = null;
  formState.race = null;
  formState.vehicleBrand = null;
  formState.vehicleName = null;
  formState.vehicleColor = null;
};
</script>

<style lang="scss">
.search-ident-evt .ant-select {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-radius: 8px;
  height: 38px;
  width: 100%;
}
.search-ident-evt .ant-select .ant-select-clear {
  right: 30px;
}
.search-ident-evt .ant-select .ant-select-selector {
  background-color: #2B2A3A !important;
  border: none !important;
  border-radius: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.search-ident-evt .ant-select .ant-select-selection-search-input {
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.search-ident-evt .ant-picker-input input {
  font-size: 13px;
}
.search-ident-evt .ant-picker-input input::placeholder {
  font-size: 14px !important;
}
.search-ident-evt .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 6px;
  padding-bottom: 6px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.search-ident-evt .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.search-ident-evt .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.search-ident-evt .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.search-ident-evt .from-to-date .ant-row .ant-picker {
  border-radius: 0 !important;
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
}
</style>
