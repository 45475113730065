<template>
  <div class="relative">
    <a-select
      class="select-multi-custom"
      :options="options"
      :fieldNames="fieldNames"
      v-model:value="valueSelected"
      :placeholder="placeholder"
      :allow-clear="allowClear"
      :is-show-search="isShowSearch"
      mode="multiple"
      :max-tag-count="maxTagCount"
      :max-tag-text-length="maxTagTextLength"
      :filter-option="
        (input, option) => filterOption(input, option, filterName)
      "
      @change="change"
    >
    </a-select>
    <cds-feather-icons
      v-if="valueSelected.length === 0"
      type="chevron-down"
      class="text-ems-gray400 absolute right-2 inline-flex items-center h-full "
    />
  </div>
</template>
<script setup>
import { filterOption } from '../../util/common-utils';
import { ref, watch } from 'vue';

const props = defineProps({
  options: {
    type: Array,
    default: [],
  },
  fieldNames: {
    type: Object,
    default: { label: 'label', value: 'value' },
  },
  value: {
    type: Array,
    default: [],
  },
  placeholder: {
    type: String,
    default: '',
  },
  allowClear: {
    type: Boolean,
    default: true,
  },
  isShowSearch: {
    type: Boolean,
    default: true,
  },
  maxTagCount: {
    type: Number,
    default: null,
  },
  maxTagTextLength: {
    type: Number,
    default: null,
  },
  filterName: {
    type: String,
    default: 'label',
  },
});
const emit = defineEmits(['change']);
const valueSelected = ref(props.value);
watch(
  () => props.value,
  (val) => (valueSelected.value = val)
);
const change = (val) => {
  emit('update:value', val);
  emit('change', val);
};
</script>
<style>
.select-multi-custom {
  height: auto !important;
}
.select-multi-custom .ant-select-selector {
  height: auto !important;
  padding: 2px 12px 2px 17px;
}
.select-multi-custom .ant-select-selection-placeholder {
  padding-left: 6px;
}
.select-multi-custom .ant-select-selection-overflow {
  padding-top: 10px;
}
.select-multi-custom .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 2px;
  top: 0px !important;
  right: 0.25rem !important;
}
.select-multi-custom .ant-select-selection-search-input {
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
</style>
